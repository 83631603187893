<template>
    <el-dropdown>
        <div class="userCom">
            <img :src="info.avatar" />
            {{ info.nickname }}
        </div>
        <el-dropdown-menu slot="dropdown">
            <router-link to="/">
              <el-dropdown-item>
                首页
              </el-dropdown-item>
            </router-link>
            <router-link to="/object_list">
              <el-dropdown-item>
                研究成果
              </el-dropdown-item>
            </router-link>
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { userInfo } from '@/api/login'
export default {
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.userInfo()
    },
    methods: {
        userInfo() {
            userInfo().then(res => {
                console.log(res);
                this.info = res.data
            }).catch(e => {
                console.log(e);
            })
        },
        logout() {
            this.$router.push('/login')
        }
    }
}
</script>

<style lang="scss" scoped>
.userCom {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FEEBBD;
    margin-left: 20px;
    cursor: pointer;

    img {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
</style>