<template>
    <div class="layout">
        <top />
        <div class="content">
            <router-view></router-view>
        </div>
        <bottom /> 
    </div>
</template>

<script>
import top from './top.vue'
import bottom from './bottom.vue'
export default {
    components: {
        top,
        bottom
    },
    data() {
        return {};
    },
    created() {
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.layout {
    min-width: 1000px;
    .content {
        margin-top: 63px;
        min-height: calc(100vh - 63px - 90px);
    }
}
</style>