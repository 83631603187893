import request from '@/utils/request'

// 用户登录接口
export function login(data) {
    return request({
        url: '/api/Login/login',
        method: 'post',
        data
    })
};

// 用户信息接口
export function userInfo(data) {
    return request({
        url: '/api/User/userInfo',
        method: 'post',
        data
    })
};