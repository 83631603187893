<template>
    <div class="topCom">
        <div class="content">
            <img @click="jumpPage({path: '/home'})" class="logo" src="https://ancientapi.pro.aisisoft.com.cn/static/images/logo.png" />
            <div class="cont">
                <div class="item" :class="path == item.path ? 'select' : ''" v-for="(item, index) in pathList" :key="index"
                    @click="jumpPage(item)">
                    {{ item.text }}
                </div>
                <user/>
            </div>
        </div>
    </div>
</template>

<script>
import user from './user/index.vue'
export default {
    components: {
        user
    },
    data() {
        return {
            pathList: [{
                text: '网站首页',
                path: '/home',
            }, {
                text: '墓室列表',
                path: '/list'
            }, {
                text: '藏品检索',
                path: '/search'
            }, {
                text: '知识图谱',
                path: '/atlas_list'
            }],
            path: ''
        }
    },
    watch: {
        $route(newVal) {
            this.path = newVal.path
        }
    },
    created() {
        this.path = this.$route.path
    },
    methods: {
        jumpPage(val) {
            console.log(val);
            if(val.path != this.$route.path) {
                this.$router.push(val.path)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.topCom {
    background: #683C19;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;

    .content {
        width: 1000px;
        height: 63px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            cursor: pointer;
        }
        .cont {
            display: flex;
            .item {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFEBBA;
                padding: 10px 20px;
                margin: 0 10px;
                cursor: pointer;
            }

            .select {
                color: #6B4626;
                background: url('@/assets/image/topbj.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}</style>