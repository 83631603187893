import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '@/layout/index.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/login.vue")
  },
  { path: '/404', component: () => import('@/views/error-page/404.vue'), hidden: true },
  { path: '*', redirect: '/404', hidden: true, },
  {
    path: "/",
    redirect: '/home',
    component: Layout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import("../views/home.vue")
    }, {
      path: '/list',
      name: 'list',
      component: () => import("../views/list.vue")
    }, {
      path: '/tomb_detail',
      name: 'tomb_detail',
      component: () => import("../views/tomb_detail.vue")
    }, {
      path: '/search',
      name: 'search',
      component: () => import("../views/search.vue")
    }, {
      path: '/object_detail',
      name: 'object_detail',
      component: () => import("../views/object_detail.vue")
    }, {
      path: '/atlas_list',
      name: 'atlas_list',
      component: () => import("../views/atlas_list.vue")
    }, {
      path: '/object_list',
      name: 'object_list',
      component: () => import("../views/object_list.vue")
    }, {
      path: '/3d',
      name: '3d',
      component: () => import("../views/3d.vue")
    }, {
      path: '/3dall',
      name: '3dall',
      component: () => import("../views/3dall.vue")
    }]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  // 滚动行为：控制滚动条的位置
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});

export default router;
