<template>
    <div class="bottomCom">
        <div class="content">
            Copyright©2023 Lymuseum.com Inc. All Rights Reserved. 洛阳博物馆 版权所有 豫ICP备12020216号-1
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.bottomCom {
    background: #48382A;
    width: 100%;
    .content {
        height: 90px;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FEEBBD;
    }
}
</style>